<script lang="ts" setup>
	import type { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size?: Sizes;
			state?: "primary" | "secondary" | "disabled" | "error" | "transparent" | "warning";
			loading?: boolean;
			error?: boolean;
			warning?: boolean;
		}>(),
		{
			size: "small",
			state: "primary",
			loading: false,
			error: false,
			warning: false,
		}
	);

	const emit = defineEmits<{
		(e: "click"): void;
	}>();

	const spinnerColor = computed(() => {
		if (props.state === "secondary" || props.state === "transparent" || props.state === "disabled") {
			return "black";
		} else {
			return "white";
		}
	});

	const spinnerSize = computed(() => {
		if (props.size === "large") {
			return "medium";
		}

		return props.size;
	});
</script>

<template>
	<button @click="emit('click')" type="button" class="button flex-horizontal" :class="[size, state, { warning: warning }, { error: error }, { loading: loading }]">
		<span><slot /></span>

		<spinner v-if="loading" :size="spinnerSize" :color="spinnerColor" />
	</button>
</template>

<style scoped lang="scss">
	.button {
		user-select: none;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: fit-content;
		position: relative;
		@include AstonMartinSans(false);
		@include FontWeightRegular(false);
		transition: all 0.2s ease-out;

		span {
			transition: opacity 0.15s ease-out;
			pointer-events: none;
		}

		&.loading {
			pointer-events: none;
			span {
				opacity: 0;
			}
		}

		.spinner {
			position: absolute;
			@include FadeIn(0.2s);
		}

		&.small {
			height: 32px;
			border-radius: 16px;
			padding-left: 30px;
			padding-right: 30px;
			letter-spacing: -0.1px;
			font-size: 14px;
		}

		&.medium {
			height: 42px;
			border-radius: 21px;
			padding-left: 35px;
			padding-right: 35px;
			letter-spacing: -0.2px;
			font-size: 16px;
		}

		&.large {
			height: 48px;
			border-radius: 24px;
			padding-left: 50px;
			padding-right: 50px;
			letter-spacing: -0.2px;
			font-size: 18px;
		}

		&.primary {
			background: var(--green-dark);
			color: white;
		}

		&.primary:hover {
			background: color.adjust($green-dark, $lightness: 5%);
		}

		&.secondary {
			background: var(--beige-dark);
			color: black;
		}

		&.secondary:hover {
			background: color.adjust($beige-dark, $lightness: -5%);
		}

		//
		&.disabled {
			background: var(--beige-dark);
			color: white;
			pointer-events: none;
		}

		&.error {
			background: var(--red);
			color: white;
			pointer-events: none;
		}

		&.warning {
			background: var(--red-light);
			color: var(--red);
		}

		&.transparent {
			background: none;
			color: black;
		}
	}
</style>
